<template>
  <div class="resultTitle">
    <!-- <div class="item item1">
      <div class="txt txt1">{{ title }}</div>
    </div> -->
    <div class="item">
      <div class="txt txt1">
        <img class="img img1" :src="require('./img/title_1.png')" />
        {{ timeStr }}
      </div>
    </div>
    <div class="line"></div>
    <div class="item">
      <div class="txt txt1">
        <img class="img img2" :src="require('./img/title_2.png')" />{{
          className
        }}
      </div>
    </div>

    <div class="line"></div>
    <div class="item">
      <div class="txt txt1">
        <img class="img img3" :src="require('./img/title_3.png')" />{{
          $route.params.diskId
        }}#转盘第{{ $route.params.seatId }}位
      </div>
    </div>
    <div class="line"></div>

    <div class="item">
      <!-- <div class="txt txt1">监测结果：</div> -->
      <div :class="'icon icon' + status"></div>
      <div :class="'txt txtr' + status">{{ statusStr }}</div>
    </div>

    <div class="line"></div>
    <div class="item">
      <div class="txt txt1">
        <img class="img img4" :src="require('./img/title_4.png')" />{{
          timeLenStr
        }}
      </div>
    </div>
    <div class="line"></div>
    <div class="item">
      <div class="txt txt1">
        <img
          v-if="mode.includes('自动')"
          class="img img5_1"
          :src="require('./img/title_5_1.png')"
        />

        <img v-else class="img img5_2" :src="require('./img/title_5_2.png')" />
        {{ mode }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Page",
  props: ["title", "status", "time", "mode", "timeLen", "className"],
  components: {},
  computed: {
    timeStr() {
      return dayjs(this.time).format("YYYY.MM.DD HH:mm:ss");
    },
    statusStr() {
      if (this.status == 0) {
        return "未生成";
      }
      if (this.status == 1) {
        return "正常";
      }
      if (this.status == 2) {
        return "异常";
      }
    },
    timeLenStr() {
      if(!this.timeLen)  return ''
      const minutes = this.timeLen / 60 >= 0 ? Math.floor(this.timeLen / 60) : "";
      const s = this.timeLen % 60;
      return minutes > 0 ? minutes + "分" + s + "秒" : s + "秒";
    },
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
