<template>
  <div class="result">
    <div class="back" @click="back">
      <div class="back-arr">
        <div class="back-arr-top"></div>
        <div class="back-arr-bottom"></div>
      </div>
      <div class="back-txt">返回</div>
    </div>
    <el-menu
      :default-active="active"
      class="set-nav"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item
        class=""
        index="ResultChart"
        :route="{
          name: 'ResultChart',
          query: $route.query,
          params: $route.params,
        }"
        >图表形式查看</el-menu-item
      >
      <el-menu-item
        class=""
        index="ResultData"
        >数据形式查看</el-menu-item
      >
    </el-menu>
    <div class="result-con">
      <ResultChart v-if="active == 'ResultChart'" />
      <ResultData v-if="active == 'ResultData'" />
    </div>

  </div>
</template>

<script>
import moment from "moment";

import ResultData from "./resultData/index.vue";
import ResultChart from "./resultChart/index.vue";

import { ElMessage } from "element-plus";

export default {
  components: { ResultData, ResultChart },
  data() {
    return {
      active: "ResultChart",
      tableHeight: document.documentElement.clientHeight * 0.5,
      tableData: [],
      handBroObj: {
        callbackName: "handTestBro",
        callbackF: this.handBroCallbackF,
      },
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      pageRes: {},

      statusText: {
        0: "未运行",
        1: "数据正常",
        2: "数据异常",
        3: "通信异常",
      },
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    handleSelect(index) {
      this.active = index;
    },
    handBroCallbackF(res) {
      // console.log("handBroCallbackF**********");
      // console.log(this);
      // console.log(res);
      // this.loading.close();
      if (res.code == 0) {
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            handTest: this.$route.query.handTest
              ? parseInt(this.$route.query.handTest) + 1
              : 1,
          },
        });
      } else {
        if (res.msg) {
          ElMessage(res.msg);
        } else {
          ElMessage("系统出错");
        }
      }
    },
    async hand() {
      try {
        let res = await this.$myRequest({
          url: "/handTestReq",
          method: "post",
          data: {
            diskId: this.$route.params.diskId, //转盘id
            seatId: this.$route.params.seatId, //位置id
          },
        });
        res = res.data;
        if (res.code == 0) {
          this.$router.replace({
            name: this.$route.name,
            params: this.$route.params,
            query: {
              handHistoryId: res.data.historyId,
              // ...this.$route.query,
              handTest: this.$route.query.handTest
                ? parseInt(this.$route.query.handTest) + 1
                : 1,
            },
          });
        } else {
          if (res.msg) {
            ElMessage(res.msg);
          } else {
            ElMessage("系统出错");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    formatterMode(row, column) {
      let option = { 0: "手动", 1: "自动" };

      return option[row.mode];
    },
    formatterTarget(row, column) {
      if (row.mode == 0) {
        return `${row.diskId}号转盘${row.seatId}号位置`;
      } else {
        return "全部";
      }
    },
    formatterTime(row, column) {
      return moment(row.time).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {},
  beforeUnmount() {},
};
</script>
<style scoped lang="less" src="./index.less"></style>
