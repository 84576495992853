const data = {
  code: 0,
  data: {
    result: {
      status: 1,
      time: 1660795353602,
      className: "班次1",
      mode: "自动",
      timeLen: 523,
    },
    data: {
      sensor1: {
        mv: "58.30",
        mp: "0.38",
        a: {
          ms: "149",
          pm: "14.9",
        },
        b: {
          ms: "447",
          pm: "44.7",
        },
        c: {
          ms: "125",
          pm: "12.5",
        },
        d: {
          ms: "280",
          pm: "28.0",
        },
        ab: {
          ms: "596",
          pm: "59.6",
        },
        cd: {
          ms: "405",
          pm: "40.5",
        },
        pulse: {
          ms: "1001",
          pm: "59.9",
        },
        limp: {
          ms: "2",
          pm: "0.2",
        },
      },
      sensor2: {
        mv: "57.53",
        mp: "-0.39",
        a: {
          ms: "160",
          pm: "16.0",
        },
        b: {
          ms: "434",
          pm: "43.4",
        },
        c: {
          ms: "116",
          pm: "11.6",
        },
        d: {
          ms: "291",
          pm: "29.1",
        },
        ab: {
          ms: "594",
          pm: "59.4",
        },
        cd: {
          ms: "407",
          pm: "40.7",
        },
        pulse: {
          ms: "1001",
          pm: "59.9",
        },
      },
    },
    analysisResultList: [],
  },
};

export { data };
