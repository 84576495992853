const data = {
  code: 0,
  data: {
    result: {
      status: 1,
      time: 1660809387264,
      className: "班次2",
      mode: "自动",
      timeLen: 425,
    },
    pressure: "60",
    data: [
      {
        sensor: 1,
        status: 1,
        time: [
          "0.000",
          "0.005",
          "0.010",
          "0.015",
          "0.020",
          "0.025",
          "0.030",
          "0.035",
          "0.040",
          "0.045",
          "0.050",
          "0.055",
          "0.060",
          "0.065",
          "0.070",
          "0.075",
          "0.080",
          "0.085",
          "0.090",
          "0.095",
          "0.100",
          "0.105",
          "0.110",
          "0.115",
          "0.120",
          "0.125",
          "0.130",
          "0.135",
          "0.140",
          "0.145",
          "0.150",
          "0.155",
          "0.160",
          "0.165",
          "0.170",
          "0.175",
          "0.180",
          "0.185",
          "0.190",
          "0.195",
          "0.200",
          "0.205",
          "0.210",
          "0.215",
          "0.220",
          "0.225",
          "0.230",
          "0.235",
          "0.240",
          "0.245",
          "0.250",
          "0.255",
          "0.260",
          "0.265",
          "0.270",
          "0.275",
          "0.280",
          "0.285",
          "0.290",
          "0.295",
          "0.300",
          "0.305",
          "0.310",
          "0.315",
          "0.320",
          "0.325",
          "0.330",
          "0.335",
          "0.340",
          "0.345",
          "0.350",
          "0.355",
          "0.360",
          "0.365",
          "0.370",
          "0.375",
          "0.380",
          "0.385",
          "0.390",
          "0.395",
          "0.400",
          "0.405",
          "0.410",
          "0.415",
          "0.420",
          "0.425",
          "0.430",
          "0.435",
          "0.440",
          "0.445",
          "0.450",
          "0.455",
          "0.460",
          "0.465",
          "0.470",
          "0.475",
          "0.480",
          "0.485",
          "0.490",
          "0.495",
          "0.500",
          "0.505",
          "0.510",
          "0.515",
          "0.520",
          "0.525",
          "0.530",
          "0.535",
          "0.540",
          "0.545",
          "0.550",
          "0.555",
          "0.560",
          "0.565",
          "0.570",
          "0.575",
          "0.580",
          "0.585",
          "0.590",
          "0.595",
          "0.600",
          "0.605",
          "0.610",
          "0.615",
          "0.620",
          "0.625",
          "0.630",
          "0.635",
          "0.640",
          "0.645",
          "0.650",
          "0.655",
          "0.660",
          "0.665",
          "0.670",
          "0.675",
          "0.680",
          "0.685",
          "0.690",
          "0.695",
          "0.700",
          "0.705",
          "0.710",
          "0.715",
          "0.720",
          "0.725",
          "0.730",
          "0.735",
          "0.740",
          "0.745",
          "0.750",
          "0.755",
          "0.760",
          "0.765",
          "0.770",
          "0.775",
          "0.780",
          "0.785",
          "0.790",
          "0.795",
          "0.800",
          "0.805",
          "0.810",
          "0.815",
          "0.820",
          "0.825",
          "0.830",
          "0.835",
          "0.840",
          "0.845",
          "0.850",
          "0.855",
          "0.860",
          "0.865",
          "0.870",
          "0.875",
          "0.880",
          "0.885",
          "0.890",
          "0.895",
          "0.900",
          "0.905",
          "0.910",
          "0.915",
          "0.920",
          "0.925",
          "0.930",
          "0.935",
          "0.940",
          "0.945",
          "0.950",
          "0.955",
          "0.960",
          "0.965",
          "0.970",
          "0.975",
          "0.980",
          "0.985",
          "0.990",
          "0.995",
          "1.000",
          "1.005",
          "1.010",
          "1.015",
          "1.020",
          "1.025",
          "1.030",
          "1.035",
          "1.040",
          "1.045",
          "1.050",
          "1.055",
          "1.060",
          "1.065",
          "1.070",
          "1.075",
          "1.080",
          "1.085",
          "1.090",
          "1.095",
          "1.100",
          "1.105",
          "1.110",
          "1.115",
          "1.120",
          "1.125",
          "1.130",
          "1.135",
          "1.140",
          "1.145",
          "1.150",
          "1.155",
          "1.160",
          "1.165",
          "1.170",
          "1.175",
          "1.180",
          "1.185",
          "1.190",
          "1.195",
          "1.200",
          "1.205",
          "1.210",
          "1.215",
          "1.220",
          "1.225",
          "1.230",
          "1.235",
          "1.240",
          "1.245",
          "1.250",
          "1.255",
          "1.260",
          "1.265",
          "1.270",
          "1.275",
          "1.280",
          "1.285",
          "1.290",
          "1.295",
          "1.300",
          "1.305",
          "1.310",
          "1.315",
          "1.320",
          "1.325",
          "1.330",
          "1.335",
          "1.340",
          "1.345",
          "1.350",
          "1.355",
          "1.360",
          "1.365",
          "1.370",
          "1.375",
          "1.380",
          "1.385",
          "1.390",
          "1.395",
          "1.400",
          "1.405",
          "1.410",
          "1.415",
          "1.420",
          "1.425",
          "1.430",
          "1.435",
          "1.440",
          "1.445",
          "1.450",
          "1.455",
          "1.460",
          "1.465",
          "1.470",
          "1.475",
          "1.480",
          "1.485",
          "1.490",
          "1.495",
          "1.500",
          "1.505",
          "1.510",
          "1.515",
          "1.520",
          "1.525",
          "1.530",
          "1.535",
          "1.540",
          "1.545",
          "1.550",
          "1.555",
          "1.560",
          "1.565",
          "1.570",
          "1.575",
          "1.580",
          "1.585",
          "1.590",
          "1.595",
          "1.600",
          "1.605",
          "1.610",
          "1.615",
          "1.620",
          "1.625",
          "1.630",
          "1.635",
          "1.640",
          "1.645",
          "1.650",
          "1.655",
          "1.660",
          "1.665",
          "1.670",
          "1.675",
          "1.680",
          "1.685",
          "1.690",
          "1.695",
          "1.700",
          "1.705",
          "1.710",
          "1.715",
          "1.720",
          "1.725",
          "1.730",
          "1.735",
          "1.740",
          "1.745",
          "1.750",
          "1.755",
          "1.760",
          "1.765",
          "1.770",
          "1.775",
          "1.780",
          "1.785",
          "1.790",
          "1.795",
          "1.800",
          "1.805",
          "1.810",
          "1.815",
          "1.820",
          "1.825",
          "1.830",
          "1.835",
          "1.840",
          "1.845",
          "1.850",
          "1.855",
          "1.860",
          "1.865",
          "1.870",
          "1.875",
          "1.880",
          "1.885",
          "1.890",
          "1.895",
          "1.900",
          "1.905",
          "1.910",
          "1.915",
          "1.920",
          "1.925",
          "1.930",
          "1.935",
          "1.940",
          "1.945",
          "1.950",
          "1.955",
          "1.960",
          "1.965",
          "1.970",
          "1.975",
          "1.980",
          "1.985",
          "1.990",
          "1.995",
          "2.000",
          "2.005",
          "2.010",
          "2.015",
          "2.020",
          "2.025",
          "2.030",
          "2.035",
          "2.040",
          "2.045",
          "2.050",
          "2.055",
          "2.060",
          "2.065",
          "2.070",
          "2.075",
          "2.080",
          "2.085",
          "2.090",
          "2.095",
          "2.100",
          "2.105",
          "2.110",
          "2.115",
          "2.120",
          "2.125",
          "2.130",
          "2.135",
          "2.140",
          "2.145",
          "2.150",
          "2.155",
          "2.160",
          "2.165",
          "2.170",
          "2.175",
          "2.180",
          "2.185",
          "2.190",
          "2.195",
          "2.200",
          "2.205",
          "2.210",
          "2.215",
          "2.220",
          "2.225",
          "2.230",
          "2.235",
          "2.240",
          "2.245",
          "2.250",
          "2.255",
          "2.260",
          "2.265",
          "2.270",
          "2.275",
          "2.280",
          "2.285",
          "2.290",
          "2.295",
          "2.300",
          "2.305",
          "2.310",
          "2.315",
          "2.320",
          "2.325",
          "2.330",
          "2.335",
          "2.340",
          "2.345",
          "2.350",
          "2.355",
          "2.360",
          "2.365",
          "2.370",
          "2.375",
          "2.380",
          "2.385",
          "2.390",
          "2.395",
          "2.400",
          "2.405",
          "2.410",
          "2.415",
          "2.420",
          "2.425",
          "2.430",
          "2.435",
          "2.440",
          "2.445",
          "2.450",
          "2.455",
          "2.460",
          "2.465",
          "2.470",
          "2.475",
          "2.480",
          "2.485",
          "2.490",
          "2.495",
          "2.500",
          "2.505",
          "2.510",
          "2.515",
          "2.520",
          "2.525",
          "2.530",
          "2.535",
          "2.540",
          "2.545",
          "2.550",
          "2.555",
          "2.560",
          "2.565",
          "2.570",
          "2.575",
          "2.580",
          "2.585",
          "2.590",
          "2.595",
          "2.600",
          "2.605",
          "2.610",
          "2.615",
          "2.620",
          "2.625",
          "2.630",
          "2.635",
          "2.640",
          "2.645",
          "2.650",
          "2.655",
          "2.660",
          "2.665",
          "2.670",
          "2.675",
          "2.680",
          "2.685",
          "2.690",
          "2.695",
          "2.700",
          "2.705",
          "2.710",
          "2.715",
          "2.720",
          "2.725",
          "2.730",
          "2.735",
          "2.740",
          "2.745",
          "2.750",
          "2.755",
          "2.760",
          "2.765",
          "2.770",
          "2.775",
          "2.780",
          "2.785",
          "2.790",
          "2.795",
          "2.800",
          "2.805",
          "2.810",
          "2.815",
          "2.820",
          "2.825",
          "2.830",
          "2.835",
          "2.840",
          "2.845",
          "2.850",
          "2.855",
          "2.860",
          "2.865",
          "2.870",
          "2.875",
          "2.880",
          "2.885",
          "2.890",
          "2.895",
          "2.900",
          "2.905",
          "2.910",
          "2.915",
          "2.920",
          "2.925",
          "2.930",
          "2.935",
          "2.940",
          "2.945",
          "2.950",
          "2.955",
          "2.960",
          "2.965",
          "2.970",
          "2.975",
          "2.980",
          "2.985",
          "2.990",
          "2.995",
          "3.000",
          "3.005",
          "3.010",
          "3.015",
          "3.020",
          "3.025",
          "3.030",
          "3.035",
          "3.040",
          "3.045",
          "3.050",
          "3.055",
          "3.060",
          "3.065",
          "3.070",
          "3.075",
          "3.080",
          "3.085",
          "3.090",
          "3.095",
          "3.100",
          "3.105",
          "3.110",
          "3.115",
          "3.120",
          "3.125",
          "3.130",
          "3.135",
          "3.140",
          "3.145",
          "3.150",
          "3.155",
          "3.160",
          "3.165",
          "3.170",
          "3.175",
          "3.180",
          "3.185",
          "3.190",
          "3.195",
          "3.200",
          "3.205",
          "3.210",
          "3.215",
          "3.220",
          "3.225",
          "3.230",
          "3.235",
          "3.240",
          "3.245",
          "3.250",
          "3.255",
          "3.260",
          "3.265",
          "3.270",
          "3.275",
          "3.280",
          "3.285",
          "3.290",
          "3.295",
          "3.300",
          "3.305",
          "3.310",
          "3.315",
          "3.320",
          "3.325",
          "3.330",
          "3.335",
          "3.340",
          "3.345",
          "3.350",
          "3.355",
          "3.360",
          "3.365",
          "3.370",
          "3.375",
          "3.380",
          "3.385",
          "3.390",
          "3.395",
          "3.400",
          "3.405",
          "3.410",
          "3.415",
          "3.420",
          "3.425",
          "3.430",
          "3.435",
          "3.440",
          "3.445",
          "3.450",
          "3.455",
          "3.460",
          "3.465",
          "3.470",
          "3.475",
          "3.480",
          "3.485",
          "3.490",
          "3.495",
          "3.500",
          "3.505",
          "3.510",
          "3.515",
          "3.520",
          "3.525",
          "3.530",
          "3.535",
          "3.540",
          "3.545",
          "3.550",
          "3.555",
          "3.560",
          "3.565",
          "3.570",
          "3.575",
          "3.580",
          "3.585",
          "3.590",
          "3.595",
          "3.600",
          "3.605",
          "3.610",
          "3.615",
          "3.620",
          "3.625",
          "3.630",
          "3.635",
          "3.640",
          "3.645",
          "3.650",
          "3.655",
          "3.660",
          "3.665",
          "3.670",
          "3.675",
          "3.680",
          "3.685",
          "3.690",
          "3.695",
          "3.700",
          "3.705",
          "3.710",
          "3.715",
          "3.720",
          "3.725",
          "3.730",
          "3.735",
          "3.740",
          "3.745",
          "3.750",
          "3.755",
          "3.760",
          "3.765",
          "3.770",
          "3.775",
          "3.780",
          "3.785",
          "3.790",
          "3.795",
          "3.800",
          "3.805",
          "3.810",
          "3.815",
          "3.820",
          "3.825",
          "3.830",
          "3.835",
          "3.840",
          "3.845",
          "3.850",
          "3.855",
          "3.860",
          "3.865",
          "3.870",
          "3.875",
          "3.880",
          "3.885",
          "3.890",
          "3.895",
          "3.900",
          "3.905",
          "3.910",
          "3.915",
          "3.920",
          "3.925",
          "3.930",
          "3.935",
          "3.940",
          "3.945",
          "3.950",
          "3.955",
          "3.960",
          "3.965",
          "3.970",
          "3.975",
          "3.980",
          "3.985",
          "3.990",
          "3.995",
          "4.000",
          "4.005",
          "4.010",
          "4.015",
          "4.020",
          "4.025",
          "4.030",
          "4.035",
          "4.040",
          "4.045",
          "4.050",
          "4.055",
          "4.060",
          "4.065",
          "4.070",
          "4.075",
          "4.080",
          "4.085",
          "4.090",
          "4.095",
          "4.100",
          "4.105",
          "4.110",
          "4.115",
          "4.120",
          "4.125",
          "4.130",
          "4.135",
          "4.140",
          "4.145",
          "4.150",
          "4.155",
          "4.160",
          "4.165",
          "4.170",
          "4.175",
          "4.180",
          "4.185",
          "4.190",
          "4.195",
          "4.200",
          "4.205",
          "4.210",
          "4.215",
          "4.220",
          "4.225",
          "4.230",
          "4.235",
          "4.240",
          "4.245",
          "4.250",
          "4.255",
          "4.260",
          "4.265",
          "4.270",
          "4.275",
          "4.280",
          "4.285",
          "4.290",
          "4.295",
          "4.300",
          "4.305",
          "4.310",
          "4.315",
          "4.320",
          "4.325",
          "4.330",
          "4.335",
          "4.340",
          "4.345",
          "4.350",
          "4.355",
          "4.360",
          "4.365",
          "4.370",
          "4.375",
          "4.380",
          "4.385",
          "4.390",
          "4.395",
          "4.400",
          "4.405",
          "4.410",
          "4.415",
          "4.420",
          "4.425",
          "4.430",
          "4.435",
          "4.440",
          "4.445",
          "4.450",
          "4.455",
          "4.460",
          "4.465",
          "4.470",
          "4.475",
          "4.480",
          "4.485",
          "4.490",
          "4.495",
          "4.500",
          "4.505",
          "4.510",
          "4.515",
          "4.520",
          "4.525",
          "4.530",
          "4.535",
          "4.540",
          "4.545",
          "4.550",
          "4.555",
          "4.560",
          "4.565",
          "4.570",
          "4.575",
          "4.580",
          "4.585",
          "4.590",
          "4.595",
          "4.600",
          "4.605",
          "4.610",
          "4.615",
          "4.620",
          "4.625",
          "4.630",
          "4.635",
          "4.640",
          "4.645",
          "4.650",
          "4.655",
          "4.660",
          "4.665",
          "4.670",
          "4.675",
          "4.680",
          "4.685",
          "4.690",
          "4.695",
          "4.700",
          "4.705",
          "4.710",
          "4.715",
          "4.720",
          "4.725",
          "4.730",
          "4.735",
          "4.740",
          "4.745",
          "4.750",
          "4.755",
          "4.760",
          "4.765",
          "4.770",
          "4.775",
          "4.780",
          "4.785",
          "4.790",
          "4.795",
          "4.800",
          "4.805",
          "4.810",
          "4.815",
          "4.820",
          "4.825",
          "4.830",
          "4.835",
          "4.840",
          "4.845",
          "4.850",
          "4.855",
          "4.860",
          "4.865",
          "4.870",
          "4.875",
          "4.880",
          "4.885",
          "4.890",
          "4.895",
          "4.900",
          "4.905",
          "4.910",
          "4.915",
          "4.920",
          "4.925",
          "4.930",
          "4.935",
          "4.940",
          "4.945",
          "4.950",
          "4.955",
          "4.960",
          "4.965",
          "4.970",
          "4.975",
          "4.980",
          "4.985",
          "4.990",
          "4.995",
        ],
        value: [
          "38.28",
          "33.4",
          "30.47",
          "27.05",
          "25.1",
          "23.15",
          "21.68",
          "20.71",
          "19.24",
          "18.27",
          "16.8",
          "15.82",
          "14.36",
          "13.38",
          "11.92",
          "10.45",
          "9.48",
          "8.01",
          "7.03",
          "6.06",
          "5.08",
          "4.1",
          "3.62",
          "3.13",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "1.17",
          "13.87",
          "20.22",
          "24.12",
          "26.57",
          "29.01",
          "31.45",
          "33.4",
          "35.36",
          "37.31",
          "39.26",
          "40.73",
          "42.19",
          "43.17",
          "44.63",
          "45.61",
          "46.59",
          "47.56",
          "48.54",
          "49.03",
          "50.0",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "52.93",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.86",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "47.07",
          "40.73",
          "34.87",
          "31.45",
          "28.52",
          "26.08",
          "24.12",
          "22.17",
          "21.19",
          "19.73",
          "18.75",
          "17.29",
          "16.31",
          "14.85",
          "13.38",
          "11.92",
          "10.94",
          "9.48",
          "8.5",
          "7.52",
          "6.55",
          "5.57",
          "4.59",
          "4.1",
          "3.13",
          "2.64",
          "1.66",
          "1.66",
          "1.17",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "9.96",
          "18.27",
          "23.64",
          "25.59",
          "28.52",
          "30.47",
          "32.91",
          "34.87",
          "36.82",
          "38.28",
          "40.24",
          "41.7",
          "42.68",
          "44.14",
          "45.12",
          "46.1",
          "47.07",
          "48.05",
          "49.03",
          "49.52",
          "50.0",
          "50.49",
          "51.47",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "53.42",
          "53.42",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.35",
          "55.86",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.37",
          "42.68",
          "36.82",
          "32.91",
          "29.5",
          "27.05",
          "24.61",
          "22.66",
          "21.68",
          "20.22",
          "19.24",
          "17.78",
          "16.8",
          "15.34",
          "14.36",
          "12.89",
          "11.43",
          "9.96",
          "8.99",
          "8.01",
          "7.03",
          "6.06",
          "5.08",
          "4.1",
          "3.62",
          "2.64",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "5.08",
          "15.82",
          "22.17",
          "24.61",
          "27.54",
          "29.98",
          "31.94",
          "34.38",
          "35.84",
          "37.8",
          "39.75",
          "41.21",
          "42.19",
          "43.66",
          "44.63",
          "45.61",
          "47.07",
          "47.56",
          "48.54",
          "49.52",
          "50.0",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "52.93",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "45.61",
          "39.75",
          "33.89",
          "30.96",
          "28.03",
          "25.59",
          "23.64",
          "22.17",
          "20.71",
          "19.24",
          "18.27",
          "17.29",
          "15.82",
          "14.36",
          "13.38",
          "11.92",
          "10.94",
          "9.48",
          "8.5",
          "7.03",
          "6.06",
          "5.57",
          "4.59",
          "3.62",
          "3.13",
          "2.15",
          "1.66",
          "1.17",
          "1.17",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.69",
          "13.38",
          "20.22",
          "24.12",
          "26.57",
          "29.01",
          "31.45",
          "33.4",
          "35.36",
          "37.31",
          "39.26",
          "40.73",
          "42.19",
          "43.17",
          "44.63",
          "45.61",
          "46.59",
          "47.56",
          "48.54",
          "49.03",
          "49.52",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "52.93",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.86",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "49.03",
          "41.21",
          "35.36",
          "31.94",
          "29.01",
          "26.57",
          "24.12",
          "22.17",
          "21.19",
          "19.73",
          "18.75",
          "17.29",
          "16.31",
          "14.85",
          "13.87",
          "12.41",
          "10.94",
          "9.96",
          "8.5",
          "7.52",
          "6.55",
          "5.57",
          "4.59",
          "4.1",
          "3.13",
          "2.64",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "9.96",
          "17.29",
          "23.15",
          "25.59",
          "28.03",
          "30.47",
          "32.43",
          "34.87",
          "36.82",
          "38.28",
          "39.75",
          "41.21",
          "42.68",
          "44.14",
          "45.12",
          "46.1",
          "47.07",
          "48.05",
          "49.03",
          "49.52",
          "50.0",
          "50.49",
          "51.47",
          "51.96",
          "51.96",
          "52.45",
          "52.93",
          "52.93",
          "53.42",
          "53.42",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
        ],
      },
      {
        sensor: 2,
        status: 1,
        time: [
          "0.000",
          "0.005",
          "0.010",
          "0.015",
          "0.020",
          "0.025",
          "0.030",
          "0.035",
          "0.040",
          "0.045",
          "0.050",
          "0.055",
          "0.060",
          "0.065",
          "0.070",
          "0.075",
          "0.080",
          "0.085",
          "0.090",
          "0.095",
          "0.100",
          "0.105",
          "0.110",
          "0.115",
          "0.120",
          "0.125",
          "0.130",
          "0.135",
          "0.140",
          "0.145",
          "0.150",
          "0.155",
          "0.160",
          "0.165",
          "0.170",
          "0.175",
          "0.180",
          "0.185",
          "0.190",
          "0.195",
          "0.200",
          "0.205",
          "0.210",
          "0.215",
          "0.220",
          "0.225",
          "0.230",
          "0.235",
          "0.240",
          "0.245",
          "0.250",
          "0.255",
          "0.260",
          "0.265",
          "0.270",
          "0.275",
          "0.280",
          "0.285",
          "0.290",
          "0.295",
          "0.300",
          "0.305",
          "0.310",
          "0.315",
          "0.320",
          "0.325",
          "0.330",
          "0.335",
          "0.340",
          "0.345",
          "0.350",
          "0.355",
          "0.360",
          "0.365",
          "0.370",
          "0.375",
          "0.380",
          "0.385",
          "0.390",
          "0.395",
          "0.400",
          "0.405",
          "0.410",
          "0.415",
          "0.420",
          "0.425",
          "0.430",
          "0.435",
          "0.440",
          "0.445",
          "0.450",
          "0.455",
          "0.460",
          "0.465",
          "0.470",
          "0.475",
          "0.480",
          "0.485",
          "0.490",
          "0.495",
          "0.500",
          "0.505",
          "0.510",
          "0.515",
          "0.520",
          "0.525",
          "0.530",
          "0.535",
          "0.540",
          "0.545",
          "0.550",
          "0.555",
          "0.560",
          "0.565",
          "0.570",
          "0.575",
          "0.580",
          "0.585",
          "0.590",
          "0.595",
          "0.600",
          "0.605",
          "0.610",
          "0.615",
          "0.620",
          "0.625",
          "0.630",
          "0.635",
          "0.640",
          "0.645",
          "0.650",
          "0.655",
          "0.660",
          "0.665",
          "0.670",
          "0.675",
          "0.680",
          "0.685",
          "0.690",
          "0.695",
          "0.700",
          "0.705",
          "0.710",
          "0.715",
          "0.720",
          "0.725",
          "0.730",
          "0.735",
          "0.740",
          "0.745",
          "0.750",
          "0.755",
          "0.760",
          "0.765",
          "0.770",
          "0.775",
          "0.780",
          "0.785",
          "0.790",
          "0.795",
          "0.800",
          "0.805",
          "0.810",
          "0.815",
          "0.820",
          "0.825",
          "0.830",
          "0.835",
          "0.840",
          "0.845",
          "0.850",
          "0.855",
          "0.860",
          "0.865",
          "0.870",
          "0.875",
          "0.880",
          "0.885",
          "0.890",
          "0.895",
          "0.900",
          "0.905",
          "0.910",
          "0.915",
          "0.920",
          "0.925",
          "0.930",
          "0.935",
          "0.940",
          "0.945",
          "0.950",
          "0.955",
          "0.960",
          "0.965",
          "0.970",
          "0.975",
          "0.980",
          "0.985",
          "0.990",
          "0.995",
          "1.000",
          "1.005",
          "1.010",
          "1.015",
          "1.020",
          "1.025",
          "1.030",
          "1.035",
          "1.040",
          "1.045",
          "1.050",
          "1.055",
          "1.060",
          "1.065",
          "1.070",
          "1.075",
          "1.080",
          "1.085",
          "1.090",
          "1.095",
          "1.100",
          "1.105",
          "1.110",
          "1.115",
          "1.120",
          "1.125",
          "1.130",
          "1.135",
          "1.140",
          "1.145",
          "1.150",
          "1.155",
          "1.160",
          "1.165",
          "1.170",
          "1.175",
          "1.180",
          "1.185",
          "1.190",
          "1.195",
          "1.200",
          "1.205",
          "1.210",
          "1.215",
          "1.220",
          "1.225",
          "1.230",
          "1.235",
          "1.240",
          "1.245",
          "1.250",
          "1.255",
          "1.260",
          "1.265",
          "1.270",
          "1.275",
          "1.280",
          "1.285",
          "1.290",
          "1.295",
          "1.300",
          "1.305",
          "1.310",
          "1.315",
          "1.320",
          "1.325",
          "1.330",
          "1.335",
          "1.340",
          "1.345",
          "1.350",
          "1.355",
          "1.360",
          "1.365",
          "1.370",
          "1.375",
          "1.380",
          "1.385",
          "1.390",
          "1.395",
          "1.400",
          "1.405",
          "1.410",
          "1.415",
          "1.420",
          "1.425",
          "1.430",
          "1.435",
          "1.440",
          "1.445",
          "1.450",
          "1.455",
          "1.460",
          "1.465",
          "1.470",
          "1.475",
          "1.480",
          "1.485",
          "1.490",
          "1.495",
          "1.500",
          "1.505",
          "1.510",
          "1.515",
          "1.520",
          "1.525",
          "1.530",
          "1.535",
          "1.540",
          "1.545",
          "1.550",
          "1.555",
          "1.560",
          "1.565",
          "1.570",
          "1.575",
          "1.580",
          "1.585",
          "1.590",
          "1.595",
          "1.600",
          "1.605",
          "1.610",
          "1.615",
          "1.620",
          "1.625",
          "1.630",
          "1.635",
          "1.640",
          "1.645",
          "1.650",
          "1.655",
          "1.660",
          "1.665",
          "1.670",
          "1.675",
          "1.680",
          "1.685",
          "1.690",
          "1.695",
          "1.700",
          "1.705",
          "1.710",
          "1.715",
          "1.720",
          "1.725",
          "1.730",
          "1.735",
          "1.740",
          "1.745",
          "1.750",
          "1.755",
          "1.760",
          "1.765",
          "1.770",
          "1.775",
          "1.780",
          "1.785",
          "1.790",
          "1.795",
          "1.800",
          "1.805",
          "1.810",
          "1.815",
          "1.820",
          "1.825",
          "1.830",
          "1.835",
          "1.840",
          "1.845",
          "1.850",
          "1.855",
          "1.860",
          "1.865",
          "1.870",
          "1.875",
          "1.880",
          "1.885",
          "1.890",
          "1.895",
          "1.900",
          "1.905",
          "1.910",
          "1.915",
          "1.920",
          "1.925",
          "1.930",
          "1.935",
          "1.940",
          "1.945",
          "1.950",
          "1.955",
          "1.960",
          "1.965",
          "1.970",
          "1.975",
          "1.980",
          "1.985",
          "1.990",
          "1.995",
          "2.000",
          "2.005",
          "2.010",
          "2.015",
          "2.020",
          "2.025",
          "2.030",
          "2.035",
          "2.040",
          "2.045",
          "2.050",
          "2.055",
          "2.060",
          "2.065",
          "2.070",
          "2.075",
          "2.080",
          "2.085",
          "2.090",
          "2.095",
          "2.100",
          "2.105",
          "2.110",
          "2.115",
          "2.120",
          "2.125",
          "2.130",
          "2.135",
          "2.140",
          "2.145",
          "2.150",
          "2.155",
          "2.160",
          "2.165",
          "2.170",
          "2.175",
          "2.180",
          "2.185",
          "2.190",
          "2.195",
          "2.200",
          "2.205",
          "2.210",
          "2.215",
          "2.220",
          "2.225",
          "2.230",
          "2.235",
          "2.240",
          "2.245",
          "2.250",
          "2.255",
          "2.260",
          "2.265",
          "2.270",
          "2.275",
          "2.280",
          "2.285",
          "2.290",
          "2.295",
          "2.300",
          "2.305",
          "2.310",
          "2.315",
          "2.320",
          "2.325",
          "2.330",
          "2.335",
          "2.340",
          "2.345",
          "2.350",
          "2.355",
          "2.360",
          "2.365",
          "2.370",
          "2.375",
          "2.380",
          "2.385",
          "2.390",
          "2.395",
          "2.400",
          "2.405",
          "2.410",
          "2.415",
          "2.420",
          "2.425",
          "2.430",
          "2.435",
          "2.440",
          "2.445",
          "2.450",
          "2.455",
          "2.460",
          "2.465",
          "2.470",
          "2.475",
          "2.480",
          "2.485",
          "2.490",
          "2.495",
          "2.500",
          "2.505",
          "2.510",
          "2.515",
          "2.520",
          "2.525",
          "2.530",
          "2.535",
          "2.540",
          "2.545",
          "2.550",
          "2.555",
          "2.560",
          "2.565",
          "2.570",
          "2.575",
          "2.580",
          "2.585",
          "2.590",
          "2.595",
          "2.600",
          "2.605",
          "2.610",
          "2.615",
          "2.620",
          "2.625",
          "2.630",
          "2.635",
          "2.640",
          "2.645",
          "2.650",
          "2.655",
          "2.660",
          "2.665",
          "2.670",
          "2.675",
          "2.680",
          "2.685",
          "2.690",
          "2.695",
          "2.700",
          "2.705",
          "2.710",
          "2.715",
          "2.720",
          "2.725",
          "2.730",
          "2.735",
          "2.740",
          "2.745",
          "2.750",
          "2.755",
          "2.760",
          "2.765",
          "2.770",
          "2.775",
          "2.780",
          "2.785",
          "2.790",
          "2.795",
          "2.800",
          "2.805",
          "2.810",
          "2.815",
          "2.820",
          "2.825",
          "2.830",
          "2.835",
          "2.840",
          "2.845",
          "2.850",
          "2.855",
          "2.860",
          "2.865",
          "2.870",
          "2.875",
          "2.880",
          "2.885",
          "2.890",
          "2.895",
          "2.900",
          "2.905",
          "2.910",
          "2.915",
          "2.920",
          "2.925",
          "2.930",
          "2.935",
          "2.940",
          "2.945",
          "2.950",
          "2.955",
          "2.960",
          "2.965",
          "2.970",
          "2.975",
          "2.980",
          "2.985",
          "2.990",
          "2.995",
          "3.000",
          "3.005",
          "3.010",
          "3.015",
          "3.020",
          "3.025",
          "3.030",
          "3.035",
          "3.040",
          "3.045",
          "3.050",
          "3.055",
          "3.060",
          "3.065",
          "3.070",
          "3.075",
          "3.080",
          "3.085",
          "3.090",
          "3.095",
          "3.100",
          "3.105",
          "3.110",
          "3.115",
          "3.120",
          "3.125",
          "3.130",
          "3.135",
          "3.140",
          "3.145",
          "3.150",
          "3.155",
          "3.160",
          "3.165",
          "3.170",
          "3.175",
          "3.180",
          "3.185",
          "3.190",
          "3.195",
          "3.200",
          "3.205",
          "3.210",
          "3.215",
          "3.220",
          "3.225",
          "3.230",
          "3.235",
          "3.240",
          "3.245",
          "3.250",
          "3.255",
          "3.260",
          "3.265",
          "3.270",
          "3.275",
          "3.280",
          "3.285",
          "3.290",
          "3.295",
          "3.300",
          "3.305",
          "3.310",
          "3.315",
          "3.320",
          "3.325",
          "3.330",
          "3.335",
          "3.340",
          "3.345",
          "3.350",
          "3.355",
          "3.360",
          "3.365",
          "3.370",
          "3.375",
          "3.380",
          "3.385",
          "3.390",
          "3.395",
          "3.400",
          "3.405",
          "3.410",
          "3.415",
          "3.420",
          "3.425",
          "3.430",
          "3.435",
          "3.440",
          "3.445",
          "3.450",
          "3.455",
          "3.460",
          "3.465",
          "3.470",
          "3.475",
          "3.480",
          "3.485",
          "3.490",
          "3.495",
          "3.500",
          "3.505",
          "3.510",
          "3.515",
          "3.520",
          "3.525",
          "3.530",
          "3.535",
          "3.540",
          "3.545",
          "3.550",
          "3.555",
          "3.560",
          "3.565",
          "3.570",
          "3.575",
          "3.580",
          "3.585",
          "3.590",
          "3.595",
          "3.600",
          "3.605",
          "3.610",
          "3.615",
          "3.620",
          "3.625",
          "3.630",
          "3.635",
          "3.640",
          "3.645",
          "3.650",
          "3.655",
          "3.660",
          "3.665",
          "3.670",
          "3.675",
          "3.680",
          "3.685",
          "3.690",
          "3.695",
          "3.700",
          "3.705",
          "3.710",
          "3.715",
          "3.720",
          "3.725",
          "3.730",
          "3.735",
          "3.740",
          "3.745",
          "3.750",
          "3.755",
          "3.760",
          "3.765",
          "3.770",
          "3.775",
          "3.780",
          "3.785",
          "3.790",
          "3.795",
          "3.800",
          "3.805",
          "3.810",
          "3.815",
          "3.820",
          "3.825",
          "3.830",
          "3.835",
          "3.840",
          "3.845",
          "3.850",
          "3.855",
          "3.860",
          "3.865",
          "3.870",
          "3.875",
          "3.880",
          "3.885",
          "3.890",
          "3.895",
          "3.900",
          "3.905",
          "3.910",
          "3.915",
          "3.920",
          "3.925",
          "3.930",
          "3.935",
          "3.940",
          "3.945",
          "3.950",
          "3.955",
          "3.960",
          "3.965",
          "3.970",
          "3.975",
          "3.980",
          "3.985",
          "3.990",
          "3.995",
          "4.000",
          "4.005",
          "4.010",
          "4.015",
          "4.020",
          "4.025",
          "4.030",
          "4.035",
          "4.040",
          "4.045",
          "4.050",
          "4.055",
          "4.060",
          "4.065",
          "4.070",
          "4.075",
          "4.080",
          "4.085",
          "4.090",
          "4.095",
          "4.100",
          "4.105",
          "4.110",
          "4.115",
          "4.120",
          "4.125",
          "4.130",
          "4.135",
          "4.140",
          "4.145",
          "4.150",
          "4.155",
          "4.160",
          "4.165",
          "4.170",
          "4.175",
          "4.180",
          "4.185",
          "4.190",
          "4.195",
          "4.200",
          "4.205",
          "4.210",
          "4.215",
          "4.220",
          "4.225",
          "4.230",
          "4.235",
          "4.240",
          "4.245",
          "4.250",
          "4.255",
          "4.260",
          "4.265",
          "4.270",
          "4.275",
          "4.280",
          "4.285",
          "4.290",
          "4.295",
          "4.300",
          "4.305",
          "4.310",
          "4.315",
          "4.320",
          "4.325",
          "4.330",
          "4.335",
          "4.340",
          "4.345",
          "4.350",
          "4.355",
          "4.360",
          "4.365",
          "4.370",
          "4.375",
          "4.380",
          "4.385",
          "4.390",
          "4.395",
          "4.400",
          "4.405",
          "4.410",
          "4.415",
          "4.420",
          "4.425",
          "4.430",
          "4.435",
          "4.440",
          "4.445",
          "4.450",
          "4.455",
          "4.460",
          "4.465",
          "4.470",
          "4.475",
          "4.480",
          "4.485",
          "4.490",
          "4.495",
          "4.500",
          "4.505",
          "4.510",
          "4.515",
          "4.520",
          "4.525",
          "4.530",
          "4.535",
          "4.540",
          "4.545",
          "4.550",
          "4.555",
          "4.560",
          "4.565",
          "4.570",
          "4.575",
          "4.580",
          "4.585",
          "4.590",
          "4.595",
          "4.600",
          "4.605",
          "4.610",
          "4.615",
          "4.620",
          "4.625",
          "4.630",
          "4.635",
          "4.640",
          "4.645",
          "4.650",
          "4.655",
          "4.660",
          "4.665",
          "4.670",
          "4.675",
          "4.680",
          "4.685",
          "4.690",
          "4.695",
          "4.700",
          "4.705",
          "4.710",
          "4.715",
          "4.720",
          "4.725",
          "4.730",
          "4.735",
          "4.740",
          "4.745",
          "4.750",
          "4.755",
          "4.760",
          "4.765",
          "4.770",
          "4.775",
          "4.780",
          "4.785",
          "4.790",
          "4.795",
          "4.800",
          "4.805",
          "4.810",
          "4.815",
          "4.820",
          "4.825",
          "4.830",
          "4.835",
          "4.840",
          "4.845",
          "4.850",
          "4.855",
          "4.860",
          "4.865",
          "4.870",
          "4.875",
          "4.880",
          "4.885",
          "4.890",
          "4.895",
          "4.900",
          "4.905",
          "4.910",
          "4.915",
          "4.920",
          "4.925",
          "4.930",
          "4.935",
          "4.940",
          "4.945",
          "4.950",
          "4.955",
          "4.960",
          "4.965",
          "4.970",
          "4.975",
          "4.980",
          "4.985",
          "4.990",
          "4.995",
        ],
        value: [
          "50.0",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "53.42",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.35",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "54.89",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "44.14",
          "37.8",
          "32.91",
          "29.98",
          "27.05",
          "24.61",
          "22.66",
          "21.19",
          "19.73",
          "18.75",
          "17.29",
          "16.31",
          "15.34",
          "13.87",
          "12.41",
          "11.43",
          "9.96",
          "8.99",
          "8.01",
          "6.55",
          "5.57",
          "4.59",
          "4.1",
          "3.13",
          "2.64",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "1.17",
          "12.89",
          "19.73",
          "23.64",
          "26.08",
          "28.52",
          "30.96",
          "32.91",
          "34.87",
          "36.82",
          "38.28",
          "39.75",
          "41.21",
          "42.68",
          "43.66",
          "45.12",
          "46.1",
          "47.07",
          "47.56",
          "48.54",
          "49.03",
          "50.0",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "51.96",
          "52.45",
          "52.93",
          "52.93",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "54.89",
          "54.89",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "47.07",
          "40.24",
          "34.38",
          "31.45",
          "28.03",
          "25.1",
          "23.15",
          "21.68",
          "20.22",
          "19.24",
          "17.78",
          "16.8",
          "15.82",
          "14.36",
          "12.89",
          "11.92",
          "10.45",
          "9.48",
          "8.01",
          "7.03",
          "6.06",
          "5.08",
          "4.59",
          "3.62",
          "2.64",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "9.48",
          "17.78",
          "22.66",
          "25.1",
          "27.54",
          "29.98",
          "32.43",
          "34.38",
          "35.84",
          "37.8",
          "39.26",
          "40.73",
          "42.19",
          "43.17",
          "44.63",
          "45.61",
          "46.59",
          "47.56",
          "48.05",
          "49.03",
          "49.52",
          "50.0",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "53.42",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "54.89",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "50.49",
          "42.19",
          "36.33",
          "31.94",
          "28.52",
          "26.08",
          "23.64",
          "22.17",
          "20.71",
          "19.73",
          "18.27",
          "17.29",
          "15.82",
          "14.85",
          "13.38",
          "11.92",
          "10.94",
          "9.48",
          "8.5",
          "7.52",
          "6.55",
          "5.57",
          "4.59",
          "3.62",
          "3.13",
          "2.64",
          "1.66",
          "1.17",
          "1.17",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "6.55",
          "16.31",
          "21.68",
          "24.61",
          "27.05",
          "29.5",
          "31.94",
          "33.89",
          "35.84",
          "37.31",
          "39.26",
          "40.73",
          "42.19",
          "43.17",
          "44.63",
          "45.61",
          "46.59",
          "47.56",
          "48.05",
          "49.03",
          "49.52",
          "50.0",
          "50.49",
          "50.98",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "53.42",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.35",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "44.14",
          "37.8",
          "32.91",
          "29.5",
          "26.57",
          "24.61",
          "22.66",
          "21.19",
          "19.73",
          "18.75",
          "17.29",
          "16.31",
          "14.85",
          "13.87",
          "12.41",
          "11.43",
          "9.96",
          "8.99",
          "7.52",
          "6.55",
          "5.57",
          "5.08",
          "4.1",
          "3.13",
          "2.64",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "12.41",
          "19.24",
          "23.64",
          "25.59",
          "28.03",
          "30.47",
          "32.43",
          "34.38",
          "36.33",
          "38.28",
          "39.75",
          "41.21",
          "42.19",
          "43.66",
          "44.63",
          "45.61",
          "46.59",
          "47.56",
          "48.54",
          "49.03",
          "49.52",
          "50.49",
          "50.98",
          "51.47",
          "51.47",
          "51.96",
          "52.45",
          "52.45",
          "52.93",
          "53.42",
          "53.91",
          "53.91",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.4",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "55.86",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.35",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "56.84",
          "55.86",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "54.89",
          "54.89",
          "54.89",
          "54.89",
          "54.89",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.37",
          "55.86",
          "55.86",
          "47.56",
          "40.24",
          "34.38",
          "30.96",
          "28.03",
          "25.1",
          "23.15",
          "21.68",
          "20.22",
          "19.24",
          "17.78",
          "16.8",
          "15.82",
          "14.36",
          "12.89",
          "11.92",
          "10.45",
          "9.48",
          "8.01",
          "7.03",
          "6.06",
          "5.08",
          "4.1",
          "3.62",
          "2.64",
          "2.15",
          "1.66",
          "1.17",
          "0.69",
          "0.69",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "-0.29",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "0.2",
          "8.5",
          "16.31",
          "22.17",
          "24.61",
          "27.05",
          "29.5",
          "31.94",
          "33.89",
          "35.84",
          "37.31",
          "39.26",
          "40.73",
          "41.7",
          "43.17",
          "44.14",
          "45.61",
          "46.59",
          "47.07",
          "48.05",
        ],
      },
    ],
  },
};

export {data}