<template>
  <div class="resultChart">
    <ResultTitle v-if="result" :title="`${$route.params.diskId}#转盘第${$route.params.seatId}位脉动监测数据`"
      :status="result?.status" :time="result?.time" :mode="result?.mode" :timeLen="result?.timeLen"
      :className="result?.className"></ResultTitle>
    <div class="resultChart-con">
      <div id="chart" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ResultTitle from "../../components/resultTitle/index.vue";
import { data } from './data'

export default {
  name: "Page",
  components: {
    ResultTitle,
  },
  data() {
    return {
      result: null,
    };
  },
  watch: {
    $route(val, old) {
      // console.log("watch route");
      // console.log(val);
      // console.log(old);
      if (val.name == old.name) {
        console.log("init by route");
        this.init();
      }
    },
  },
  methods: {
    setChart(data, pressure) {
      let result = data.result;
      pressure = parseFloat(pressure);
      data = data.data;
      this.chart && this.chart.dispose();
      let series = [];
      let legendData = [];
      let xData = [];
      xData = data[0].time;

      //  {
      //       name: "北京猪场",
      //       type: "line",
      //       data: [21.7, 21.5, 22, 23.2],
      //     },
      data.forEach((v, k) => {
        let valueData = [];

        v.value.forEach((i, a) => {
          v.value[a] = 0 - Number(i) + "";
        });

        v.time.forEach((vv, kk) => {
          valueData.push([vv, v.value[kk]]);
        });
        legendData.push(v.sensor == 1 ? "左" : "右");

        series.push({
          name: v.sensor == 1 ? "左" : "右",
          type: "line",

          // data: v.value,
          data: valueData,
          itemStyle:
            k == 0
              ? {
                color: "#f5ee24",
              }
              : {
                color: "#62F567",
              },
          // lineStyle:
          //   k == 0 && result.status == 2
          //     ? {
          //         color: "#ff0000",
          //       }
          //     : {},
          markLine: {
            lineStyle: {
              color: "#B63DC4",
            },
            data: [
              {
                name: "pressure",
                yAxis: -(pressure - 4),
              },
              {
                name: "pressure",
                yAxis: -4,
              },
            ],
          },
        });
        // if (k == 0) {
        //   series[0].markLine = {
        //     lineStyle: {
        //       color: "#B63DC4",
        //     },
        //     data: [
        //       {
        //         name: "pressure",
        //         yAxis: pressure - 4,
        //       },
        //       {
        //         name: "pressure",
        //         yAxis: 4,
        //       },
        //     ],
        //   };
        // }
      });
      // series.push();
      let dom = document.getElementById("chart");
      let chart = echarts.init(dom);
      // series[1].yAxisIndex = 1
      let option;
      option = {
        grid: {
          left: 20,
          bottom: 60,
          right: 30,
          top: 40,
          // width: 470,
          // height: 90,
          containLabel: true,
          // show: true,
          // backgroundColor: 'rgba(0,0,0,0.1)',
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
          // formatter: '{a}: {c0}<br />{b1}: {c1}'
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        legend: {
          type: "scroll",
          icon: "rect",
          pageIconSize: 8,
          pageIconColor: "#aaa",
          pageIconInactiveColor: "#2f4554",
          pageTextStyle: {
            color: "#ffffff",
            fontSize: 8,
          },
          data: legendData,
          itemWidth: 20, // 图块长
          itemHeight: 20, // 图块宽
          itemGap: 20, // 图块间距
          bottom: 10,
          textStyle: {
            fontSize: 19,
            color: "#FFFFFF",
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
        },
        xAxis: [
          {
            type: "category",
            // type: "value",
            // data: xData,
            // data: [0, 1, 2, 3, 4, 5, 6, 7],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              padding: [0, 0, 0, 60],
              color: "#4D9EED",
              fontSize: 14,
              formatter: "{value} sec",
              // interval:1,
              interval: function (index, value) {
                // return 0.982;
                // console.log(value);
                if (value % 1 == 0) {
                  return value;
                } else {
                  return false;
                }
              },
            },
          },
        ],
        yAxis: [
          {
            scale: true,
            inverse: true,
            axisLine: {
              symbol: ["none", "none"],
              symbolOffset: [0, 20],
              show: true,
              lineStyle: {
                color: "#495078",

                shadowOffsetY: -20,
                shadowColor: "#495078",
              },
            },
            type: "value",
            name: "KPa",
            nameLocation: "start",
            nameTextStyle: {
              padding: [-20, 0, 0, 0],
              color: "#50aad1",
              verticalAlign: "top",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#396bb5",
                type: "solid",
              },
            },
            axisLabel: {
              formatter: "{value}",
              // formatter: function (value) {
              //   return -value;
              // },
              color: "#50aad1",
              fontSize: 14,
            },
          },
          {
            scale: true,
            position: "right",
            axisLine: {
              symbol: ["none", "none"],
              symbolOffset: [0, 20],
              show: true,
              lineStyle: {
                color: "#495078",

                shadowOffsetY: -20,
                shadowColor: "#495078",
              },
            },
            type: "value",
            nameLocation: "end",
            nameTextStyle: {
              padding: [-20, 0, 0, 0],
              color: "#50aad1",
              verticalAlign: "top",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#396bb5",
                type: "solid",
              },
            },
            axisLabel: {
              formatter: "{value}",
              color: "#50aad1",
              fontSize: 14,
            },
          },
        ],
        series,
        dataZoom: {
          start: 0,
          type: "inside",
        },
      };
      chart.setOption(option);
      this.chart = chart;
    },
    async init() {
      let sendData;
      if (this.$route.query.cutHistoryId) {
        sendData = { testResultId: this.$route.query.cutHistoryId };
      } else {
        sendData = {
          diskId: this.$route.params.diskId, //转盘id
          seatId: this.$route.params.seatId, //位置id
          segmentId: this.$route.query.segmentId,
        };
        if (this.$route.query.handTest) {
          sendData.historyId = this.$route.query.handHistoryId;
        } else {
          if (this.$route.params.historyId != 0) {
            sendData.historyId = this.$route.params.historyId;
          } else {
          }
        }
      }

      // try {
      //   let res = await this.$myRequest({
      //     url: "/getResultChartReq",
      //     method: "post",
      //     data: sendData,
      //   });
      // res = res.data;
      let res = data;
      if (!res.data) {
        this.chart && this.chart.dispose();
        this.result = null;
        return;
      }
      if (res.data.data.length) {
        this.setChart(res.data, res.data.pressure);
      }

      this.result = res.data.result;
      // } catch (err) {
      //   console.log(err);
      // }
    },
  },
  beforeUnmount() {
    this.chart && this.chart.dispose();
  },
  mounted() {
    console.log("init by mounted");
    this.init();
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
