<template>
  <div class="resultData">
    <ResultTitle v-if="result" :title="`${$route.params.diskId}#转盘第${$route.params.seatId}位脉动监测数据`"
      :status="result.status" :time="result.time" :mode="result.mode" :timeLen="result.timeLen"
      :className="result.className"></ResultTitle>
    <div class="con" v-if="resultData.length">
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="item1">
        <div class="box-item">测试通道</div>
        <div class="box-item">真空度</div>
        <div class="box-item">B项压降</div>
        <div class="box-item">&nbsp;</div>

        <div class="box-item">A相</div>
        <div class="box-item">B相</div>
        <div class="box-item">C相</div>
        <div class="box-item">D相</div>
        <div class="box-item">挤奶阶段</div>
        <div class="box-item">按摩阶段</div>
        <div class="box-item">脉动频率</div>
        <div class="box-item">不对称性(B1-B2)</div>
      </div>
      <div v-for="(item, index) in resultData" :key="index" :class="index == 0 ? 'item2' : 'item2 item2-end'">
        <div class="box-item box-title">传感器{{ index + 1 }}</div>
        <div class="box-item">{{ item.mv }}</div>
        <div class="box-item">{{ item.mp }}</div>
        <div class="box-item-params">
          <div class="box-item-params-value">
            <div class="box-item">
              <div class="box-item-b box-title2">ms</div>
              <div class="box-item-b box-title2">%</div>
              <div class="box-item-b box-title2">p/m</div>
            </div>

            <div class="box-item">
              <div class="box-item-b">{{ item.a.ms }}</div>
              <div class="box-item-b">{{ item.a.pm }}</div>
              <div class="box-item-b"></div>
            </div>
            <div class="box-item">
              <div class="box-item-b">{{ item.b.ms }}</div>
              <div class="box-item-b">{{ item.b.pm }}</div>
              <div class="box-item-b"></div>
            </div>
            <div class="box-item">
              <div class="box-item-b">{{ item.c.ms }}</div>
              <div class="box-item-b">{{ item.c.pm }}</div>
              <div class="box-item-b"></div>
            </div>
            <div class="box-item">
              <div class="box-item-b">{{ item.d.ms }}</div>
              <div class="box-item-b">{{ item.d.pm }}</div>
              <div class="box-item-b"></div>
            </div>
            <div class="box-item">
              <div class="box-item-b">{{ item.ab.ms }}</div>
              <div class="box-item-b">{{ item.ab.pm }}</div>
              <div class="box-item-b"></div>
            </div>
            <div class="box-item">
              <div class="box-item-b">{{ item.cd.ms }}</div>
              <div class="box-item-b">{{ item.cd.pm }}</div>
              <div class="box-item-b"></div>
            </div>
            <div class="box-item">
              <div class="box-item-b">{{ item.pulse.ms }}</div>
              <div class="box-item-b"></div>
              <div class="box-item-b">{{ item.pulse.pm }}</div>
            </div>
            <div class="box-item">
              <template v-if="item.limp">
                <div class="box-item-b">{{ item.limp.ms }}</div>
                <div class="box-item-b">{{ item.limp.pm }}</div>
                <div class="box-item-b"></div>
              </template>
            </div>
          </div>
          <div class="box-item-params-line"></div>
          <div class="box-item-params-err">
            <div class="box-item box-title3">异常</div>
            <div v-for="(v, k) in itemErrArr" :key="k" :class="v == 21 ? 'box-item box-item-err-h' : 'box-item'">
              <template v-if="item['err' + v]">
                <el-tooltip v-for="(vv, kk) in item['err' + v]" :key="kk" class="item" effect="dark" :content="vv.desc"
                  placement="top-start">
                  <div :class="'error-item error-item' + vv.errorType">
                    {{ vv.code }}
                  </div>
                </el-tooltip>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="item3">
        <div class="box-item box-title3">异常</div>
        <div class="box-item">
          <template v-if="errAll">
            <el-tooltip v-for="(vv, kk) in errAll" :key="kk" class="item" effect="dark" :content="vv.desc"
              placement="top-start">
              <div :class="'error-item error-item' + vv.errorType">
                {{ vv.code }}
              </div>
            </el-tooltip>
          </template>
        </div>
      </div>
    </div>
    <div class="err-box">
      <div class="err-box-item" v-for="(item, index) in analysisResultList" :key="index">
        <div :class="'error-item error-item' + item.errorType">
          {{ item.code }}
        </div>
        <div class="err-desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultTitle from "../../components/resultTitle/index.vue";

import { data } from './data'

export default {
  name: "Page",
  components: {
    ResultTitle,
  },
  data() {
    return {
      itemErrArr: [11, 12, 13, 14, 21, 22],
      errAll: null,
      result: null,
      resultData: [],
      analysisResultList: [],
    };
  },
  watch: {
    $route(val, old) {
      // console.log("watch route");
      // console.log(val);
      // console.log(old);
      if (val.name == old.name) {
        this.init();
      }
    },
  },
  methods: {
    dealData(sensor) {
      console.log("dealdata");
      console.log(sensor);
      if (!sensor.mv) {
        sensor.mv = " ";
      }
      if (!sensor.mp) {
        sensor.mp = " ";
      }
      if (!sensor.a) {
        sensor.a = {};
      }
      if (!sensor.b) {
        sensor.b = {};
      }
      if (!sensor.c) {
        sensor.c = {};
      }
      if (!sensor.d) {
        sensor.d = {};
      }
      if (!sensor.ab) {
        sensor.ab = {};
      }
      if (!sensor.cd) {
        sensor.cd = {};
      }
      if (!sensor.pulse) {
        sensor.pulse = {};
      }
      if (!sensor.limp) {
        sensor.limp = {};
      }
    },
    async init() {
      let sendData;
      if (this.$route.query.cutHistoryId) {
        sendData = { testResultId: this.$route.query.cutHistoryId };
      } else {
        sendData = {
          diskId: this.$route.params.diskId, //转盘id
          seatId: this.$route.params.seatId, //位置id
          segmentId: this.$route.query.segmentId,
        };
        if (this.$route.query.handTest) {
          sendData.historyId = this.$route.query.handHistoryId;
        } else {
          if (this.$route.params.historyId != 0) {
            sendData.historyId = this.$route.params.historyId;
          } else {
          }
        }
      }

      // try {
      //   let res = await this.$myRequest({
      //     url: "/getResultDataReq",
      //     method: "post",
      //     data: sendData,
      //   });
      //   res = res.data;

      let res = data

      if (!res.data) {
        this.result = null;
        this.resultData = [];
        return;
      }

      this.analysisResultList = res.data.analysisResultList;
      this.result = res.data.result;
      if (res.data.data) {
        res.data.analysisResultList.forEach((v, k) => {
          if (v.position != 99) {
            if (!res.data.data["sensor" + v.sensorNum]["err" + v.position]) {
              res.data.data["sensor" + v.sensorNum]["err" + v.position] = [];
            }
            res.data.data["sensor" + v.sensorNum]["err" + v.position].push(v);
          } else {
            if (!res.data.data.errAll) {
              res.data.data.errAll = [];
            }
            res.data.data.errAll.push(v);
          }
        });
        this.dealData(res.data.data.sensor1);
        this.dealData(res.data.data.sensor2);
        this.resultData[0] = res.data.data.sensor1;
        this.resultData[1] = res.data.data.sensor2;
        res.data.data.errAll ? (this.errAll = res.data.data.errAll) : "";
      }
      // } catch (err) {
      //   console.log(err);
      // }

    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
